import * as Sentry from '@sentry/react';
import { version as reactVersion, Component, ReactNode, ErrorInfo } from 'react';
import { version as reactDomVersion } from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { FormattedMessage } from 'react-intl';

import deniedImg from '@ui/assets/img/denied.svg';
import { Notice } from '@ui/notice';

type Error = {
  error: never;
};

interface IErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
}

export class ErrorBoundary extends Component<{ children?: ReactNode }, IErrorBoundaryState> {
  override state = { error: null, errorInfo: null, hasError: false };

  override componentDidCatch(error: never, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    });
  }

  override render(): ReactNode {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <Notice
            title={ReactDOMServer.renderToString(<FormattedMessage id="errorBoundary.errorCaptured" />)}
            description={
              <>
                <FormattedMessage id="errorBoundary.moduleError" /> <br />
                <FormattedMessage id="errorBoundary.contactUsIfProblemPersists" />
                {/* FIXME */}
                {process.env.NODE_ENV === 'development' && (
                  <details style={{ whiteSpace: 'pre-wrap', textAlign: 'left', marginTop: '2em' }}>
                    <summary>
                      ReactJS: {reactVersion}, ReactDOM: {reactDomVersion}
                      <br />
                      {error?.toString()}
                    </summary>
                    {componentStack}
                  </details>
                )}
              </>
            }
            image={deniedImg}
          />
        )}
      >
        {this.props?.children}
      </Sentry.ErrorBoundary>
    );
  }
}
