import React from 'react';
import { CSSObject } from 'styled-components';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ISelectedRowData<D> {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: D[];
}

export interface ITableColumn<D> {
  /**
   * @description
   * Unique identifier for each column.
   */
  id: string;
  /**
   * @description
   * Display name of a column.
   */
  name?: string | JSX.Element;
  /**
   * @description
   * A cell lets you use custom components to display data.
   */
  cell: (row: D, rowIndex: number, column: ITableColumn<D>) => React.ReactNode;
  /**
   * @description
   * Footer text
   */
  Footer?: React.ReactNode | JSX.Element;
  /**
   * @description
   * Right aligns content in the cell
   */
  right?: boolean;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  /**
   * @description
   * Allows you to customize the css of the cell.
   * The style isn't applied to the table header.
   */
  style?: CSSObject;
  /**
   * @description
   * Allows you to customize the css of the header cell.
   * The style isn't applied to the table cell.
   */
  headerStyle?: CSSObject;
  sortable?: boolean;
  /**
   * @description
   * This field is used to identify pre-sorted column by comparing `sortField` with `defaultSortField`.
   */
  sortField?: string;
}

export interface ITableProps<D extends Record<string, unknown>> {
  data: D[];
  columns: ITableColumn<D>[];
  /**
   * @description
   * Data item unique property to map data to rows.
   */
  keyField: keyof D;
  /**
   * @description
   * Enables selecting rows feature.
   */
  selectable?: boolean;
  /**
   * @description
   * Defines which type of select row indicator to use.
   * @default checkbox
   */
  selectableIndicator?: 'checkbox' | 'switch';
  /**
   * @description
   * Predicate function to pre-select rows.
   */
  selectableRowSelected?: (row: D) => boolean;
  /**
   * @description
   * Predicate function to disable rows based on a property in data.
   */
  selectableRowDisabled?: (row: D) => boolean;
  onSelectedRowsChange?: (selected: ISelectedRowData<D>) => void;
  /**
   * @description
   * Sets the column to be pre-sorted
   */
  defaultSortField?: string | null;
  /**
   * @description
   * Sets the sort direction of the pre-sorted column
   */
  defaultSortDirection?: SortDirection | null;
  onSort?: (column: ITableColumn<D>, sortDirection: SortDirection) => void;
  /**
   * @description
   * First loading, no data is present.
   */
  isLoading?: boolean;
  /**
   * @description
   * A component to customize no data view.
   */
  noDataComponent?: React.ReactElement;
  isError?: boolean;
  /**
   * @description
   * A component to customize an error view.
   */
  errorComponent?: React.ReactElement;
  /**
   * @default false
   */
  isHeaderHidden?: boolean;
  /**
   * @default false
   */
  isBodyHidden?: boolean;
  /**
   * @description
   * Callback to access the row. The function isn't applied to the checkbox or switch.
   */
  onRowClick?: (row: D) => void;
  /**
   * @description
   * A row which is currently active will be highlighted.
   * @example An active row can be the one which details are currently open.
   */
  isRowActive?: (row: D) => boolean;
  /**
   * @description
   * Callback for a custom component to display in the expanded row.
   */
  expandableRowComponent?: (row: D) => React.ReactElement;
  /**
   * @description
   * Predicate function to pre-expand rows.
   */
  expandableRowsExpanded?: (row: D) => boolean;
  /**
   * @description
   * Function that is fired whenever a row expanded.
   */
  onExpandRow?: (row: D) => void;
  /**
   * @description
   * The only way to deselect table rows.
   */
  autoClearRows?: boolean;
  /**
   * @description
   * Display a custom loading height.
   */
  loadingHeight?: string;
  hasStatePagination?: boolean;
}
